import {Link} from "@swan-io/chicane";
import {Router} from "../../router/Router";

export const Footer = () => {
    return (
        <>
            <footer className="mt-16">
                <div className="sm:px-8">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="border-t border-zinc-100 pt-10 pb-16 dark:border-zinc-700/40">
                            <div className="relative px-4 sm:px-8 lg:px-12">
                                <div className="mx-auto max-w-2xl lg:max-w-5xl">
                                    <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                                        <div className="flex gap-6 text-sm font-medium text-zinc-800">
                                            <Link
                                                to={Router.Home()}
                                                className="transition hover:text-teal-500 dark:hover:text-teal-400"
                                                activeClassName="text-teal-500 dark:text-teal-400"
                                            >
                                                About
                                            </Link>
                                            <Link
                                                to={Router.Blog()}
                                                className="transition hover:text-teal-500 dark:hover:text-teal-400"
                                                activeClassName="text-teal-500 dark:text-teal-400"
                                            >
                                                Blog
                                            </Link>
                                        </div>
                                        <p className="text-sm text-zinc-400 dark:text-zinc-500">
                                            © 2024 Hodun Dmytro. All rights reserved.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};