import {createRouter} from "@swan-io/chicane";


export type Routes = {
    Home: {},
    Blog: {},
    // BlogPost: { postId: string },
}

export const Router = createRouter({
    Home: "/",
    Blog: "/blog",
    // BlogPost: "/blog/:slug",
});

