import {Link} from "@swan-io/chicane";
import {Router} from "../../router/Router";

export const Header = () => {
    return (
        <>
            <header className="pointer-events-none relative z-50 flex flex-col">
                <div className="top-0 z-10 h-16 pt-6">
                    <div className="sm:px-8 top-[var(--header-top,theme(spacing.6))] w-full">
                        <div className="mx-auto max-w-7xl lg:px-8">
                            <div className="relative px-4 sm:px-8 lg:px-12">
                                <div className="mx-auto max-w-2xl lg:max-w-5xl">
                                    <div className="relative flex">
                                        <div className="flex flex-1 justify-end md:justify-center">
                                            <nav className="pointer-events-auto">
                                                <ul className="flex rounded-full bg-white/90 pr-4 pl-2 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur">
                                                    <li>
                                                        <Link
                                                            to={Router.Home()}
                                                            className="relative block px-3 py-2 transition hover:text-teal-500 dark:hover:text-teal-400"
                                                            activeClassName="text-teal-500 dark:text-teal-400 px-3 py-2"
                                                        >
                                                            About
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={Router.Blog()}
                                                            className="relative block px-3 py-2 transition hover:text-teal-500 dark:hover:text-teal-400"
                                                            activeClassName="text-teal-500 dark:text-teal-400 px-3 py-2"
                                                        >
                                                            Blog
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};