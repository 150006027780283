import React from 'react';
import './App.css';
import {RouterView} from "./router/RouterView";
import {Header} from "./components/shared/Header";
import {Footer} from "./components/shared/Footer";

function App() {
    return (
        <main className="flex h-full flex-col">
            <div className="relative">
                <Header/>
                <RouterView/>
                <Footer/>
            </div>
        </main>
    );
}


export default App;