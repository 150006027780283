import {match} from "ts-pattern";
import {HomePage} from "../Pages/HomePage";
import React from "react";
import {Router} from "./Router";
import {BlogPage} from "../Pages/BlogPage";

export const RouterView = () => {
    const route = Router.useRoute(["Home", "Blog"]);

    // route object is a discriminated union
    // @ts-ignore

    return match(route)
        .with({name: "Home"}, () => <HomePage/>)
        .with({name: "Blog"}, () => <BlogPage/>)
        // @ts-ignore
        // .with({name: "BlogPost"}, ({params}) => <BlogPostPage slug={params.slug}/>) // params are strongly typed
        .otherwise(() => <h1>404</h1>);
}