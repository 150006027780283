export interface Post {
    slug: string;
    title: string;
    description?: string;
    date: string;
    image?: string;
    link: string;
    tags: string[];
}

export const posts: Post[] = [
    {
        slug: "automated-scaffold-generation",
        title: "Streamlining Laravel Development with Automated Scaffold Generation",
        description: "Using Automated Scaffold Generation for Efficient Development",
        date: "Apr 9, 2024",
        image: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*xT0jSh2i1Q9nF1rKlFeRHg.png",
        link: "https://dedmytro.medium.com/streamlining-laravel-development-with-automated-scaffold-generation-829db5b157ab",
        tags: ["DDD", "Code Architecture", "Code Generation"]
    },
    {
        slug: "laravel-actions",
        title: "Laravel’s Action: A Simplified Guide to Cleaner Code and Testing",
        description: "Organizing code is essential in Laravel development. The Action feature facilitates this organization by encapsulating logic into reusable classes",
        date: "Mar 28, 2024",
        link: "https://dedmytro.medium.com/laravels-action-a-simplified-guide-to-cleaner-code-and-testing-8cd95e038379",
        tags: ["Patterns", "Clean Code"]
    },
]