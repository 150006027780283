import {Post, posts} from "../data/posts";

export const BlogPage = () => {

    const filteredPosts = posts.filter((post: Post) => {
        const tag = new URLSearchParams(window.location.search).get('tag');
        return !tag || post.tags.includes(tag);
    });

    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Insights for Laravel Developers
                    </h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">Explore practical tips, insights, and
                        thought-provoking ideas tailored for PHP and Laravel enthusiasts.
                    </p>
                    <div className="mt-16 lg:mt-20 space-y-16">
                        {filteredPosts.map((post) => (
                            <article key={post.slug} className="relative isolate flex flex-col gap-8 lg:flex-row">
                                <div
                                    className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-48 lg:shrink-0">
                                    {post.image && <>
                                        <img
                                            src={post.image}
                                            alt=""
                                            className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                        />
                                        <div
                                            className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
                                    </>}

                                </div>
                                <div>
                                    <div className="flex items-center gap-x-4 text-xs flex-wrap">
                                        <time dateTime={post.date} className="text-gray-500">
                                            {post.date}
                                        </time>
                                        {post.tags.map((tag) => (
                                            <a
                                                key={tag}
                                                href={`?tag=${tag}`}
                                                className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                                            >
                                                {tag}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="group relative max-w-xl">
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <a href={post.link}>
                                                <span className="absolute inset-0"/>
                                                {post.title}
                                            </a>
                                        </h3>
                                        <p className="mt-5 text-sm leading-6 text-gray-600">{post.description}</p>
                                    </div>
                                    {/*<div className="mt-6 flex border-t border-gray-900/5 pt-6">*/}
                                    {/*    <div className="relative flex items-center gap-x-4">*/}
                                    {/*        <div className="text-sm leading-6">*/}
                                    {/*            <p className="font-semibold text-gray-900">*/}
                                    {/*                <a href='https://dedmytro.medium.com/'>*/}
                                    {/*                    <span className="absolute inset-0" />*/}
                                    {/*                    DeDmytro*/}
                                    {/*                </a>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="text-gray-600">Technical Leader, Web and Mobile Developer</p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}